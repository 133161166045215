<template>
	<div>
		<div class="eng-tab-section__info">
			<span class="eng-tab-section__info__item">
				<VLink
					size="small"
					weight="semibold"
					@click.stop.prevent="
						openInfoAlert('What does this mean?', 'ewg-info')
					"
				>
					<img
						src="@/assets/imgs/icons/icon-info.svg"
						class="question"
						alt="info icon"
					/>What does this mean?
				</VLink>
			</span>
			<span class="eng-tab-section__info__item">
				<VLink
					size="small"
					weight="semibold"
					@click.stop.prevent="
						openInfoAlert('Got questions about this?', 'ewg-question')
					"
				>
					<img
						src="@/assets/imgs/icons/icon-question.svg"
						class="question"
						alt="question icon"
					/>Got questions about this?
				</VLink>
			</span>
		</div>

		<div
			v-for="process in processes"
			:key="process.slug"
			:class="{ active: ewFilters.process === process.slug }"
			:ref="process.slug"
			class="eng-tab-section__content__link"
		>
			<div
				class="eng-tab-section__content__link__item"
				@click="setActiveProcess(process.slug)"
			>
				<div class="subtitle subtitle--small">
					{{ process.name }}
				</div>
				<div v-if="ewFilters.process === process.slug">
					<img src="@/assets/imgs/icons/icon-arrow-right-white.svg" alt="" />
				</div>
				<div v-else>
					<img src="@/assets/imgs/icons/icon-arrow-right.svg" alt="" />
				</div>
			</div>

			<div
				class="eng-tab-section__content__link__content"
				v-if="ewFilters.process === process.slug"
			>
				<div
					v-if="sectors.length"
					class="eng-tab-section__content__filter__item"
				>
					<VText size="big" classes="title" weight="bold">
						Focus by sector:
					</VText>

					<VDefaultField class="form__group__inline form__wrap">
						<template #field>
							<div
								class="form__group__inner"
								v-for="sector in sectors"
								:key="sector.slug"
							>
								<VRadio
									class="radio"
									:id="sector.slug"
									:label="sector.name"
									:input-value="sector.slug"
									v-model="ewFilters.sector"
								/>
							</div>
						</template>
					</VDefaultField>
				</div>

				<VMultiselect
					label="title"
					trackBy="slug"
					placeholder="Select a stage to jump to"
					:multiple="false"
					:options="subprocesses"
					:close-on-select="true"
					@select="handleSelect"
					class="multiselect-content"
				/>

				<div
					v-for="subprocess in subprocesses"
					:key="subprocess.slug"
					:ref="subprocess.slug"
					class="eng-tab-section__content__link__card half-text"
				>
					<div class="text">
						<VHeading level="6" weight="bold">{{ subprocess.title }}</VHeading>
						<VText size="small">
							<div v-html="subprocess.shortDescription"></div>
						</VText>
					</div>
					<div class="action-btn" v-if="subprocess.hasEngagementWindow">
            <VButton
                color="outline-black"
                class="engagement-window-button"
                :title="subprocess.engagementWindowTitle"
                @click.stop="viewEngagementWindow(subprocess)"
            >{{ subprocess.engagementWindowTitle.length > 70
                ? `${subprocess.engagementWindowTitle.slice(0, 70)}...`
                : subprocess.engagementWindowTitle }}</VButton
            >
					</div>
				</div>
			</div>
		</div>

		<EngagementWindowModal
			@onSaveView="handleSaveView"
			@close="closeModals"
			v-if="engagementWindowModal"
		/>
		<DataVisualViewModal
			:errors="saveViewErrors"
			ref="saveViewModal"
			@onSubmit="submitSaveViewModal"
			@close="saveViewModal = false"
			v-if="saveViewModal"
		/>
	</div>
</template>

<script>
import {
	SAVE_VISUAL,
	FETCH_SECTORS,
	FETCH_EW_PROCESSES,
	FETCH_EW_SUBPROCESS,
	FETCH_EW_SUBPROCESSES
} from '@/store/actions.type';

import { SET_EW_FILTERS, SET_EW_SUBPROCESSES } from '@/store/mutations.type';

import { mapGetters, mapState } from 'vuex';
import Errors from '@/helpers/Errors';

import DataVisualViewModal from '@/modals/DataVisualViewModal';
import EngagementWindowModal from '@/modals/EngagementWindowModal';

import tooltipAlert from '@/mixins/tooltip-alert';
import ew from '@/mixins/ew';

export default {
	name: 'EngagementWindows',
	components: {
		DataVisualViewModal,
		EngagementWindowModal
	},
	data() {
		return {
			saveViewErrors: new Errors(),
			engagementWindowModal: false,
			saveViewModal: false
		};
	},
	mixins: [tooltipAlert, ew],
	computed: {
		...mapGetters(['loading']),
		...mapState({
			sectors: state => state.app.sectors,
			activeCountry: state => state.ewg.ewgFilters.country,
			processes: state => state.engagementWindows.processes,
			subprocess: state => state.engagementWindows.subprocess,
			subprocesses: state => state.engagementWindows.subprocesses
		}),
		ewFilters: {
			get() {
				return this.$store.state.engagementWindows.ewFilters;
			},
			set(value) {
				this.$store.commit(SET_EW_FILTERS, value);
			}
		}
	},
	watch: {
		ewFilters: {
			handler: 'fetchData',
			deep: true
		},
		activeCountry: 'resetData',
		'ewFilters.process': 'scrollToProcess'
	},
    beforeDestroy() {
        this.resetData();
    },
	async mounted() {
		await this.fetchProcesses();
		await this.fetchSectors();
		this.setData();
	},
	methods: {
		async fetchData() {
			if (!this.activeCountry) return;
			const query = {
				...this.ewFilters,
				...{ country: this.activeCountry.slug }
			};
			this.setEwRouterParams(query);
			this.$store.dispatch(FETCH_EW_SUBPROCESSES, query);
		},
		async fetchSectors() {
			const params = this.ewFilters.process
				? { type: 'ewg', process: this.ewFilters.process, country: this.activeCountry.slug }
				: { type: 'ewg' };

			await this.$store.dispatch(FETCH_SECTORS, params);

			if (this.ewFilters.sector) return;
		},
		async viewEngagementWindow(subprocess) {
			await this.$store.dispatch(FETCH_EW_SUBPROCESS, subprocess);
			this.engagementWindowModal = true;
		},
		async submitSaveViewModal(form) {
			try {
				form = {
					...form,
					filters: {
						...this.ewFilters,
						...{ country: this.activeCountry.slug }
					},
					type: 'engagement-windows'
				};
                
				await this.$store.dispatch(SAVE_VISUAL, form);

				this.saveViewModal = false;
				this.$toastr.s('Success', 'Successfully saved visual');
			} catch ({ response: { data } }) {
				this.errors.record(data);
			}
		},
		handleSaveView() {
			this.saveViewModal = true;

			this.$nextTick(() => {
				const { title, sector, process } = this.subprocess;
				const modalTitle = `${title} / ${this.activeCountry.name} / ${process.name} / ${sector.name}`;

				this.$refs.saveViewModal.form.title = modalTitle;
				this.$refs.saveViewModal.title = modalTitle;
			});
		},
		async fetchProcesses() {
			await this.$store.dispatch(FETCH_EW_PROCESSES);
		},
		setActiveProcess(process) {
			if (this.ewFilters.process == process) {
				this.$set(this.ewFilters, 'process', null);
				this.$router.replace({ name: 'engagement-windows' }).catch(err => err);
				return;
			}

			this.$set(this.ewFilters, 'process', process);
		},
		handleSelect(subprocess) {
			this.$nextTick(() =>
				this.$refs[subprocess.slug][0].scrollIntoView({
					behavior: 'smooth'
				})
			);
		},
		async scrollToProcess(process) {
      await this.fetchSectors();

      if (!process) return;

      this.$set(this.ewFilters, 'sector', this.sectors[0].slug);

			this.$nextTick(() => {
				this.$refs[process][0].scrollIntoView({
					behavior: 'smooth'
				});
			});
		},
		setData() {
			const routeParams = this.$route.query;

			if (routeParams.sector)
				this.$set(this.ewFilters, 'sector', routeParams.sector);

			if (routeParams.process) {
                this.$set(this.ewFilters, 'process', routeParams.process);
			}
		},
		closeModals() {
			this.saveViewModal = false;
			this.engagementWindowModal = false;
		},
		resetData() {
			this.ewFilters.process = null;
			this.ewFilters.sector = null;
            this.$store.commit(SET_EW_SUBPROCESSES, []);
		}
	}
};
</script>

<style scoped>
.question {
	margin-right: 15px !important;
}

/*.eng-tab-section__content__filter__item {*/
/*  display: flex;*/
/*  flex-wrap: wrap;*/
/*}*/

.engagement-window-button {
  padding: 12px 27px;
  height: unset;
  width: unset;
}

@media screen and (min-width: 767px) {
	/deep/ .row-align {
		display: flex;
		flex-wrap: wrap;
	}
}
</style>
