<template>
	<Modal
		classes="modal--welcome"
		class="modal--sub-process"
		@close="$emit('close')"
	>
		<template #header>
			<div class="modal-section__card__header__breadcrumbs">
				<div class="modal-section__card__header__breadcrumbs__img">
					<img src="@/assets/imgs/flags/flag-southafrica-medium.png" alt="" />
				</div>
				<VHeading level="5">
					{{ activeCountry.name }} / {{ subprocess.process.name }} /
					{{ subprocess.sector.name }}
				</VHeading>
			</div>
			<VHeading level="2">{{ subprocess.engagementWindowTitle }}</VHeading>
		</template>
		<template #content>
			<div>
				<div class="timeline">
					<div v-if="subprocess.timeline">
						<VHeading level="6" size="bigger" weight="bold" classes="title">
							Timeline:
						</VHeading>
						<VText size="big">
							{{ subprocess.timeline.from | date }}
							<img
								src="@/assets/imgs/icons/line-4.svg"
								alt="->"
								class="engagement-window-line"
							/>
							{{ subprocess.timeline.to | date }}
						</VText>
						<br /><br />
					</div>
					<div
						v-html="subprocess.engagementWindowDescription"
						class="description"
					></div>
					<br /><br />
				</div>
			</div>
			<div>
				<div
					class="mini-tab"
					v-if="subprocess.keyContacts.length || subprocess.resources.length"
				>
					<div class="mini-tab__tab">
						<VText
							classes="mini-tab__tab__item"
							:class="{ active: isActiveTab('contacts') }"
							@click="tab = 'contacts'"
							v-if="subprocess.keyContacts.length"
						>
							Key contacts
						</VText>
						<VText
							level="6"
							classes="mini-tab__tab__item"
							:class="{ active: isActiveTab('resources') }"
							@click="tab = 'resources'"
							v-if="subprocess.resources.length"
						>
							Resources
						</VText>
					</div>
					<div class="mini-tab__content">
						<div
							class="modal-section__card__content__list modal-section__card__content__list--modal"
						>
							<div key="contacts" v-if="isActiveTab('contacts')">
								<div
									class="modal-section__card__content__list__item modal-item word--break"
									v-for="keyContact in subprocess.keyContacts"
									:key="keyContact.id"
								>
									<div class="flex items-center justify-between w-100">
										<div class="flex items-center">
											<div>
												<img
													src="@/assets/imgs/icons/icon-arrow-point.svg"
													alt="icon arrow point"
												/>
											</div>

											<VLink
												:href="getResourceLink(keyContact)"
												target="_blank"
												size="regular"
											>
												{{ keyContact.name }}
											</VLink>
										</div>

										<div
											class="flex w-25 right justify-end"
											v-if="
											  keyContact.originalResource &&
												keyContact.type &&
													keyContact.type.includes('StakeholderIndividual')
											"
										>
											<a
												target="_blank"
												:href="keyContact.originalResource.socials.twitter"
												v-if="
													keyContact.originalResource.socials &&
														keyContact.originalResource.socials.twitter
												"
											>
												<img
													src="@/assets/imgs/social/logo-twitter.svg"
													alt="twitter"
												/>
											</a>
											<a
												target="_blank"
												:href="keyContact.originalResource.socials.linkedin"
												v-if="
													keyContact.originalResource.socials &&
														keyContact.originalResource.socials.linkedin
												"
											>
												<img
													src="@/assets/imgs/social/logo-linkedin2.svg"
													alt="linkedin"
												/>
											</a>
										</div>
										<div
											class="flex w-25 right justify-end"
											v-if="
											  keyContact.resource &&
												keyContact.type &&
													!keyContact.type.includes('StakeholderIndividual')
											"
										>
											<a
												target="_blank"
												v-if="keyContact.resource.socials.twitter"
												:href="keyContact.resource.socials.twitter"
											>
												<img
													src="@/assets/imgs/social/logo-twitter.svg"
													alt="twitter"
												/>
											</a>
											<a
												target="_blank"
												:href="keyContact.resource.socials.linkedin"
												v-if="keyContact.resource.socials.linkedin"
											>
												<img
													src="@/assets/imgs/social/logo-linkedin2.svg"
													alt="linkedin"
												/>
											</a>
											<a
												target="_blank"
												:href="keyContact.resource.socials.facebook"
												v-if="keyContact.resource.socials.facebook"
											>
												<img
													src="@/assets/imgs/social/logo-facebook.svg"
													height="17"
													alt="facebook"
												/>
											</a>
											<a
												target="_blank"
												:href="keyContact.resource.socials.instagram"
												v-if="keyContact.resource.socials.instagram"
											>
												<img
													src="@/assets/imgs/social/logo-instagram.svg"
													alt="instagram"
												/>
											</a>
											<a
												target="_blank"
												:href="keyContact.resource.website"
												v-if="keyContact.resource.website"
											>
												<img
													src="@/assets/imgs/icons/globe.svg"
													style="width: 17px"
													alt="globe"
												/>
											</a>
										</div>
									</div>
								</div>
							</div>
							<div key="resources" v-if="isActiveTab('resources')">
								<ol>
									<li
										class="modal-section__card__content__list__item modal-item word--break"
										v-for="resource in subprocess.resources"
										:key="resource.slug"
									>
										<VLink
											size="regular"
											:href="getResourceLink(resource)"
											target="_blank"
											v-bind="
												resource.target.includes('download')
													? { download: resource.name }
													: {}
											"
										>
											{{ resource.name }}
											<img
												src="@/assets/imgs/icons/icon-download-white.svg"
												alt="download icon"
												key="download-icon"
												v-if="resource.target.includes('download')"
											/>
											<img
												src="@/assets/imgs/icons/icon-external-link-white.svg"
												alt="external link icon"
												key="external-link-icon"
												v-if="resource.target.includes('external')"
											/>
										</VLink>
									</li>
								</ol>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template #footer>
			<Actions :download="false" @onSaveViewClick="$emit('onSaveView')" />

			<VButton
				color="primary"
				@click.stop="viewNextSubprocess"
				v-if="nextSubprocess"
			>
				View next engagement window
			</VButton>
		</template>
	</Modal>
</template>

<script>
import { FETCH_EW_SUBPROCESS } from '@/store/actions.type';
import { mapState } from 'vuex';

export default {
	name: 'EngagementWindowModal',
	data() {
		return {
			tab: 'contacts'
		};
	},
	computed: {
		...mapState({
			subprocesses: state => state.engagementWindows.subprocesses,
			subprocess: state => state.engagementWindows.subprocess,
			activeCountry: state => state.ewg.ewgFilters.country
		}),
		nextSubprocess() {
			const subprocessesWithDetails = this.subprocesses.filter(
				subprocess => subprocess.hasEngagementWindow
			);

			const index = subprocessesWithDetails.findIndex(
				subprocess => subprocess.id === this.subprocess.id
			);

			return index + 1 in subprocessesWithDetails
				? subprocessesWithDetails[index + 1]
				: null;
		}
	},
	mounted() {
		this.tab = this.subprocess.keyContacts.length ? 'contacts' : 'resources';
	},
	methods: {
		isActiveTab(tab) {
			return this.tab === tab;
		},
		viewNextSubprocess() {
			this.$store.dispatch(FETCH_EW_SUBPROCESS, this.nextSubprocess);
		},
		getResourceLink(link) {
			if (!link.target.includes('internal')) return link.link;

			if (link.type.includes('Organisation')) {
				return `/organisation-profile/${link.resource.slug}`;
			}

			if (
				(link.type.includes('SystemAndPolicy') ||
					link.type.includes('StakeholderIndividual')) &&
				link.resource != null
			) {
				return `/engaging-with-government/systems-and-policies?organisation=${link.resource.slug}&country=${link.resource.country.name}&sectors=${link.resource.sector.slug}`;
			}
		}
	}
};
</script>

<style scoped>
/deep/ .button--action div {
	width: 100%;
	margin: 0;
}
</style>
