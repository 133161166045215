<template>
	<Modal
		classes="modal--visual-content"
		class="modal--visual"
		@close="$emit('close')"
	>
		<template #header>
			<VHeading level="2">Save this data visual view</VHeading>
		</template>
		<template #content>
			<div>
				<VText size="small" weight="semibold">
					Save the current data visual view and access it later from
					<VLink size="small">‘Your saved visuals’</VLink>
				</VText>
				<VText
					size="big"
					weight="bold"
					classes="modal-section__card__content__subtitle"
				>
					Give this data visual a memorable name
				</VText>
				<VDefaultField :error="errors.get('title')">
					<template #field>
						<VInput
							v-model="form.title"
							type="email"
							placeholder="Policy process datadd / SGS Goal 3 / 5 focus countries"
							:error="errors.get('title')"
							@input="errors.clear('title')"
						/>
					</template>
				</VDefaultField>
			</div>
		</template>
		<template #footer>
			<VButton
				color="primary"
				classes="save-btn"
				@click="$emit('onSubmit', form)"
			>
				Save visual view
			</VButton>
		</template>
	</Modal>
</template>

<script>
export default {
	name: 'DataVisualViewModal',
	props: {
		errors: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			form: {
				title: ''
			},
			title: ''
		};
	}
};
</script>
