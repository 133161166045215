export default {
	methods: {
		setEwRouterParams(query) {
			if (!query.process || !query.sector) return;

			this.$router
				.replace({
					name: 'engagement-windows',
					query
				})
				.catch(err => err);
		}
	}
};
