import { OPEN_ALERT } from '@/store/mutations.type';
import { FETCH_TOOLTIP } from '@/store/actions.type';

export default {
	methods: {
		async openInfoAlert(title, slug) {
			const { data } = await this.$store.dispatch(FETCH_TOOLTIP, slug);

			this.$store.commit(OPEN_ALERT, {
				title,
				type: 'text',
				width: 'large',
				content: data.data.content
			});
		}
	}
};
